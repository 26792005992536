import axios from 'axios';
import {
    ShopeeAccessTokenParams,
    ShopeeAccessTokenResponse,
    ShopeeApiRequestUrlAuthorize,
    ShopeePutRequest,
    ShopeeShopInfo,
    ShopeeShopInfoParams
} from '../schemas/shopeeThirdPartyAuthorize.schemas';

export async function retrieveUrlAutohrizeShopee(
    params: ShopeeApiRequestUrlAuthorize
) {
    {
        return axios.get<string>('/v1/requestUrlShopeeAuthorize', {
            params
        });
    }
}

export async function retrieveAccessTokenShopee(
    params: ShopeeAccessTokenParams
) {
    {
        return axios.get<ShopeeAccessTokenResponse>('/v1/requestAccessToken', {
            params
        });
    }
}

export async function requestAddShopeeThirdPartner(req: ShopeePutRequest) {
    return axios.put<string>('/v1/shopee-third-partner', req);
}

export async function retrieveShopeeShopInfo(params: ShopeeShopInfoParams) {
    return axios.get<ShopeeShopInfo>('/v1/requestShopeeShopInfo', { params });
}

// export async function retrieveShopeeShopWareHouse(
//     params: ShopeeShopInfoParams
// ) {
//     {
//         return axios.get<ShopeeWareHouseResponse>(
//             '/v1/requestShopeeWareHouseInfo',
//             { params }
//         );
//     }
// }
