
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginViewModel() {
    
    const [user, setUser] = useState({
        username: '',
        password: ''
    });

    const [Width, setWidth] = useState('90%');

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setWidth(isMobile ? '100%' : '90%');
        };

        handleResize(); // Call the function initially

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    const mockUpUser = {
        username: 'audit3rd',
        password: 'audit3rdPartner'
    };

    const handleInputChange = (e: {
        target: {
            name: string;
            value: string;
        };
    }) => {
        setUser(prev => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    };

    const inputValidation = () => {
        if (!user.username || !user.password) {
            alert('username or password is empty!');

            return false;
        }

        if (
            user.username === mockUpUser.username &&
            user.password === mockUpUser.password
        ) {
            return true;
        } else {
            alert('usernmae or password is incorrect!');

            return false;
        }
    };

    const handlePressLogin = () => {
        console.log(user);
        const validationStatus = inputValidation();

        if (validationStatus) {
            navigate('/Integrationpage');
        }
    };

    return { handlePressLogin, handleInputChange,Width };
}

export default LoginViewModel;
