import React, { useEffect, useState } from 'react';

import Footer from '../Footer/Footer';
import Header from '../Headerlogout/Headerlogout';
import BannerImage from '../../../assets/images/bg-login.png';
import Shopee from '../../../assets/icons/shopee.png';
import Lazada from '../../../assets/icons/Lazada.png';
import Geer from '../../../assets/icons/Geer.png';
import Polygon from '../../../assets/icons/Polygon.png';
import Actions from '../../../assets/icons/actions.png';

function Login() {
    const [marginTop, setMarginTop] = useState('-30vh');

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setMarginTop(isMobile ? '-5vh' : '-15vh');
        };

        handleResize(); // Call the function initially

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header />
            <div
                className="min-h-screen bg-center bg-cover bg-no-repeat flex items-center justify-center"
                style={{ backgroundImage: `url(${BannerImage})` }}
            >
                <div
                    style={{
                        backgroundSize: 'cover',
                        width: '80%',
                        height: '80%',
                        marginTop: marginTop // Adjust the values based on the display size
                    }}
                >
                    <button className="shadow-lg bg-white hover:bg-gray-100 text-[#0068B5] font-semibold py-2 px-4 rounded-lg shadow flex items-center">
                        <img
                            src={Polygon}
                            alt="Integrates Icon"
                            className="mr-2"
                        />
                        <img
                            src={Geer}
                            alt="Integrates Icon"
                            className="mr-2"
                        />
                        Integrates
                    </button>
                    <br />
                    <div className="relative mx-auto bg-white rounded-lg shadow-lg overflow-hidden justify-center items-center">
                        <div
                            className="w-4/5 px-6 py-8"
                            style={{ width: '100%' }}
                        >
                            <div className="text-center font-roboto font-bold text-xl leading-23 text-[#0068B5]">
                                Select 3rd Party Integration
                            </div>
                            <br />
                            <div className="overflow-x-auto">
                                <table className="table-auto min-w-full bg-white">
                                    <thead>
                                        <tr>
                                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                Marketplaces
                                            </th>
                                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                Tutorial
                                            </th>
                                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {/* addrow */}
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <img
                                                            className="h-10 w-10 rounded-full"
                                                            src={Shopee}
                                                            alt="User Avatar"
                                                        />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm leading-5 font-medium text-gray-900">
                                                            Shopee
                                                        </div>
                                                        <div className="text-sm leading-5 text-gray-500">
                                                            Shopee API
                                                            Integration
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                <div className="text-sm leading-5 text-gray-900">
                                                    <button className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 rounded-lg focus:outline-none hover:bg-blue-700">
                                                        Shopee Guide
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-[#FF0000] text-[#000]">
                                                    Not Connected
                                                </span>
                                                {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    Connected
                                                </span> */}
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                {/* <button className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-[#F3B75B] rounded-lg focus:outline-none hover:bg-[#FF0000]">
                                                    Action
                                                </button> */}
                                                <a href="/InputShopee">
                                                    <button className="px-4 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-[#F3B75B] rounded-lg focus:outline-none hover:bg-[#F8D59F]">
                                                        <img
                                                            src={Actions}
                                                            alt="Action Icon"
                                                        />
                                                    </button>
                                                </a>
                                            </td>
                                            {/* endrow */}
                                        </tr>
                                        <tr>
                                            {/* addrow */}
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <img
                                                            className="h-10 w-10 rounded-full"
                                                            src={Lazada}
                                                            alt="User Avatar"
                                                        />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm leading-5 font-medium text-gray-900">
                                                            Lazada
                                                        </div>
                                                        <div className="text-sm leading-5 text-gray-500">
                                                            Lazada API
                                                            Integration
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                <div className="text-sm leading-5 text-gray-900">
                                                    <button className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 rounded-lg focus:outline-none hover:bg-blue-700">
                                                        Lazada Guide
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-[#FF0000] text-[#000]">
                                                    Not Connected
                                                </span> */}
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    Connected
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                                {/* <button className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-[#F3B75B] rounded-lg focus:outline-none hover:bg-[#FF0000]">
                                                    Action
                                                </button> */}
                                                <a href="/InputLazada">
                                                    <button className="px-4 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-[#F3B75B] rounded-lg focus:outline-none hover:bg-[#F8D59F]">
                                                        <img
                                                            src={Actions}
                                                            alt="Action Icon"
                                                        />
                                                    </button>
                                                </a>
                                            </td>
                                            {/* endrow */}
                                        </tr>
                                        {/* Add more table rows here */}
                                    </tbody>
                                </table>
                            </div>

                            {/* endtable */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Login;
