import React, { useEffect, useState } from 'react';
import LoginViewModel from './Login.viewmodel';

import Footer from '../Footer/Footer';
import BannerImage from '../../../assets/images/bg-login.png';
import FromBGLogin from '../../../assets/images/FromBGLogin.png';
import Logo3rd from '../../../assets/images/Logo3rd-Partner-Border.png';

function Login() {
    
    const { handlePressLogin, handleInputChange,Width } = LoginViewModel();

    return (
        <div>
            <div
                className="min-h-screen bg-center bg-cover bg-no-repeat flex items-center justify-center"
                style={{ backgroundImage: `url(${BannerImage})` }}
            >
                <div
                    className="relative mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
                    style={{
                        backgroundImage: `url(${FromBGLogin})`,
                        backgroundSize: 'cover',
                        width: '50%',
                        height: '60%'
                    }}
                >
                    <div>
                        <div
                            className="w-4/5 px-6 py-8 "
                            style={{ width: Width }}
                        >
                            {/* Left box content */}
                            <img
                                src={Logo3rd}
                                alt="Logo"
                                className="mx-auto w-64 sm:w-auto h-auto"
                            />
                            <form className="flex flex-col space-y-6 h-full">
                                <div>
                                    <label
                                        htmlFor="username"
                                        className="block text-[#9F9F9F] font-bold mb-1"
                                    >
                                        Username
                                    </label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        placeholder="Enter your username"
                                        className="block bg-[#D9D9D9] border border-gray-300 rounded-lg shadow-sm py-2 px-4 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-[#9F9F9F] font-bold mb-1"
                                    >
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter your password"
                                        className="block bg-[#D9D9D9] border border-gray-300 rounded-lg shadow-sm py-2 px-4 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div>
                                    <button
                                        type="button"
                                        className="py-3 px-6 border border-transparent rounded-md shadow-sm text-white font-bold bg-[#0068B5] hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
                                        onClick={handlePressLogin}
                                    >
                                        LOG IN
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Login;
