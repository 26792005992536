import React, { useState, useEffect } from 'react';
import Logo3rd from '../../../assets/images/Logo3rd-Partner-Border.png';

function Header() {
    return (
        <header
            className={`fixed top-0 w-full bg-[#0068B5] transition-colors duration-500 z-50`}
        >
            <nav className="px-4 py-2 flex justify-between items-center">
                <h1 className="text-lg font-bold  text-white">
                    <img
                        src={Logo3rd}
                        alt="Logo"
                        className="w-8 h-8 sm:w-12 sm:h-12"
                    />
                </h1>
                <ul className="flex space-x-5">
                    <li>
                        <a
                            href="/"
                            className="text-white hover:text-[#F3B75B] "
                        >
                            Home
                        </a>
                    </li>
                    <li>
                        <a
                            href="/login"
                            className="text-white hover:text-[#FF0000] "
                        >
                            Logout
                        </a>
                    </li>
                    {/* <li><a href="#" className="text-white hover:text-gray-600 text-white">Contact</a></li> */}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
