// In your component file
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import BannerImage from '../../../assets/images/banner.jpg';

function HomepageView() {
    return (
        <div>
            <Header />
            <div
                className="h-screen bg-center bg-cover bg-no-repeat relative"
                style={{ backgroundImage: `url(${BannerImage})` }}
            >
                <div
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white w-full"
                    style={{
                        padding: '3vh' // Adjust the value here to position it above the header
                    }}
                >
                    <div className="container mx-auto text-4xl font-bold uppercase">
                        Our Sellers Earn Money Online <br />
                        Without Buying & Keeping Stocks
                    </div>
                    <div className="container mx-auto ">
                        <button className="bg-[#0068B5] hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">
                            START HERE
                            <svg
                                className="inline-block align-middle ml-2 h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M10 3.08c-2.81 0-5.232 1.47-6.637 3.68a8.77 8.77 0 01-2.383 2.53 10.023 10.023 0 00-.677 1.106c-.21.4-.345.832-.345 1.278 0 1.07.728 1.945 1.625 1.945.297 0 .58-.07.83-.193a3.27 3.27 0 00.348-.184l.024-.015.042-.03a1 1 0 011.16 1.607l-.042.03a5.247 5.247 0 01-.706.444 8.44 8.44 0 01-1.55.72 11.042 11.042 0 005.453 1.448c3.366 0 6.343-1.67 7.72-4.08a7.88 7.88 0 011.143-1.54 9.605 9.605 0 01-.77-1.505 6.563 6.563 0 01-.583-2.423 6.24 6.24 0 013.295-5.575 1 1 0 00.34-1.363 1.007 1.007 0 00-1.383-.34 8.233 8.233 0 00-4.254 7.31c0 .406.036.798.102 1.18a1 1 0 001.985-.292c-.055-.356-.082-.72-.082-1.088 0-1.732.888-3.256 2.21-4.162a7.76 7.76 0 013.03-1.05 6.18 6.18 0 015.33 3.3 1 1 0 001.363.34 1.005 1.005 0 00.341-1.363 8.22 8.22 0 00-7.055-4.385z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HomepageView;
