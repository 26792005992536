import { parseResponseError } from '../../../common/helpers/handle-error';
import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import { ResponseLazadaMaster } from '../../../models/lazadaMaster';
import { ResponeLazadaSellerUser } from '../../../models/lazadaSellerUser';
import {
    retrieveRegisterDataLazada,
    retrieveLazadaAPIUserData,
    retrieveDefaultValue,
    requestAddLazadaThirdPartner,
    retrieveAccToken
} from '../../thirdPartyAuthorize/datasources/thirdPartyAuthorize.datasources';
import {
    LazadaAPIRequestParams,
    AddLazadaThirdPartnerRequest,
    DefaultValueParams,
    DefaultValueResponse,
    LazadaGetAccessToken
} from '../schemas/thirdPartyAuthorize.schemas';

export async function getStoreRegisterLazadaData(): Promise<
    DatasourceReturnType<ResponseError, ResponseLazadaMaster[]>
> {
    try {
        const data = (await retrieveRegisterDataLazada()).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getLazadaSellerUserData(
    params: LazadaAPIRequestParams
): Promise<DatasourceReturnType<ResponseError, ResponeLazadaSellerUser>> {
    try {
        const data = (await retrieveLazadaAPIUserData(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getDefaultValue(
    params: DefaultValueParams[]
): Promise<DatasourceReturnType<ResponseError, DefaultValueResponse[]>> {
    try {
        const data = (await retrieveDefaultValue(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addLazadaThirdPartner(
    body: AddLazadaThirdPartnerRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await requestAddLazadaThirdPartner(body)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getAccTokenByCode(
    params: LazadaAPIRequestParams
): Promise<DatasourceReturnType<ResponseError, LazadaGetAccessToken>> {
    try {
        const data = (await retrieveAccToken(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
