import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    DefaultValueParams,
    AddLazadaThirdPartnerRequest,
    LazadaAPIRequestParams,
    DefaultValueResponse
} from '../../../data/thirdPartyAuthorize/schemas/thirdPartyAuthorize.schemas';
import {
    addLazadaThirdPartner,
    getAccTokenByCode,
    getDefaultValue,
    getLazadaSellerUserData
} from '../../../data/thirdPartyAuthorize/repositories/thirdPartyAuthorize.repositories';
import { LazadaToken } from '../../../models/lazadaSellerUser';

function ThirdPartyAuthorizeViewModel() {
    const [counter, setCounter] = useState<number>(10);
    const [reqBody, setReqBody] = useState<AddLazadaThirdPartnerRequest>({
        sid: '555',
        companyCode: '1000',
        appName: '',
        region: '',
        appKey: '',
        accessToken: '',
        createdBy: '',
        createdOn: '',
        updatedBy: '',
        updatedOn: '',
        email: '',
        sellerId: '',
        sellerCompany: '',
        storeName: ''
    });
    const [lazadaToken, setLazadaToken] = useState<LazadaToken>();
    const [defaultValue, setDefaultValue] = useState<Map<string, string>>();

    const intervalRef = useRef<number | null>(null);

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    const sid = localStorage.getItem('sid');
    const companyCode = localStorage.getItem('companyCode');
    const callBack = localStorage.getItem('hosturl');

    const defaultValueKeyCode: DefaultValueParams[] = [
        { keyCode: 'LAZADA_REQUEST_API' },
        { keyCode: 'LAZADA_OPEN_PLATFORM_APPKEY' },
        { keyCode: 'LAZADA_OPEN_PLATFORM_APPSECRET' },
        { keyCode: 'LAZADA_REQUEST_TOKEN' },
        { keyCode: 'REDIRECT_URL_TO_LAZADA' }
    ];

    useEffect(() => {
        getDefaultValueForRequest(defaultValueKeyCode);
    }, [code]);

    useEffect(() => {
        if (defaultValue && defaultValue.size > 0) {
            getAccToken({
                code: code,
                urlRequestApi: defaultValue.get('LAZADA_REQUEST_TOKEN')!,
                appKey: defaultValue.get('LAZADA_OPEN_PLATFORM_APPKEY')!,
                appSecret: defaultValue.get('LAZADA_OPEN_PLATFORM_APPSECRET')!
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        if (defaultValue && lazadaToken) {
            if (lazadaToken.refresh_token && lazadaToken.refresh_token) {
                getSellerUserData({
                    accessToken: lazadaToken.access_token,
                    refreshToken: lazadaToken.refresh_token,
                    urlRequestApi: defaultValue.get('LAZADA_REQUEST_API')!,
                    appKey: defaultValue.get('LAZADA_OPEN_PLATFORM_APPKEY')!,
                    appSecret: defaultValue.get(
                        'LAZADA_OPEN_PLATFORM_APPSECRET'
                    )!
                });
            } else {
                console.error('lazadaToken values is null or empty.');
            }
        }
    }, [lazadaToken]);

    const getAccToken = async function (params: LazadaAPIRequestParams) {
        const [, data] = await getAccTokenByCode(params);

        setLazadaToken(data);
    };

    /**
     * func: getAccToken
     * urlRequestApi: "LAZADA_REQUEST_TOKEN"
     * appKey: "LAZADA_OPEN_PLATFORM_APPKEY"
     * appSecret: "LAZADA_OPEN_PLATFORM_APPSECRET"
     *
     * func: getLazadaSellerUserData
     * urlRequestApi: "LAZADA_REQUEST_API"
     * appKey: "LAZADA_OPEN_PLATFORM_APPKEY"
     * appSecret: "LAZADA_OPEN_PLATFORM_APPSECRET"
     */

    const getDefaultValueForRequest = async (params: DefaultValueParams[]) => {
        const [, defaultValuesResponse] = await getDefaultValue(params);

        //hash table + linked list
        const defaultValues = defaultValuesResponse.reduce(
            (accumulate, defaultValue) => {
                accumulate.set(defaultValue.keyCode, defaultValue.xValue);
                return accumulate;
            },
            new Map<string, string>()
        );

        setDefaultValue(defaultValues);
    };

    const getSellerUserData = async (params: LazadaAPIRequestParams) => {
        const [, data] = await getLazadaSellerUserData({
            accessToken: params.accessToken,
            urlRequestApi: params.urlRequestApi,
            appKey: params.appKey,
            appSecret: params.appSecret
        });

        if (data && lazadaToken) {
            setReqBody(prevState => ({
                ...prevState,
                sid: sid,
                companyCode: companyCode,
                storeName: data.data.name,
                appName: data.data.name_company,
                email: data.data.email,
                sellerId: data.data.seller_id,
                region: data.data.location,
                sellerCompany: data.data.name_company,
                appKey: data.data.short_code,
                accessToken: lazadaToken.access_token
            }));
        }
    };

    const startCountDown = useCallback(() => {
        intervalRef.current = window.setInterval(
            (() => {
                let localCounter = counter;
                return () => {
                    if (localCounter === 0) {
                        clearInterval(intervalRef.current!);
                        setCounter(0);
                        redirectUrl();

                        return;
                    }

                    localCounter--;
                    setCounter(timer => timer - 1);
                };
            })(),
            1000
        );
    }, [reqBody]);

    const putRequest = useMemo(async () => {
        console.log(reqBody);
        if (reqBody.appKey) {
            console.log(reqBody);
            const [, data] = await addLazadaThirdPartner(reqBody);

            console.log(data);

            if (data) {
                startCountDown();
            }
        }
    }, [reqBody]);

    const redirectUrl = useCallback(() => {
        window.location.replace(
            'https://' +
                callBack +
                '/Register/LazadaRegister.aspx' +
                `?code=${code}&refreshToken=${lazadaToken?.refresh_token}`
        );

        localStorage.clear();

        return;
    }, [reqBody]);

    return { counter, code, sid, reqBody };
}

export default ThirdPartyAuthorizeViewModel;
