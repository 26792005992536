import React, { useEffect, useState } from 'react';
import StoreRegisterViewModel from './StoreRegister.viewmodel';

function StoreRegisterView() {
    const { lazadaData } = StoreRegisterViewModel();

    return (
        <div className="">
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Region</th>
                        <th>Appkey</th>
                        <th>Seller ID</th>
                        <th>Seller Company</th>
                        <th>Store Name</th>
                        <th>SID Company</th>
                        <th>Access Token</th>
                        <th>App Name</th>
                        <th>SID</th>
                        <th>SID Company</th>
                        <th>Token</th>
                    </tr>
                </thead>
                <tbody>
                    {lazadaData &&
                        lazadaData.map((dataLazada, index) => {
                            return (
                                <tr className="break-words" key={index}>
                                    <td>{++index}</td>
                                    <td>{dataLazada.region}</td>
                                    <td>{dataLazada.appKey}</td>
                                    <td>{dataLazada.sellerId}</td>
                                    <td>{dataLazada.sellerCompany}</td>
                                    <td>{dataLazada.storeName}</td>
                                    <td>{dataLazada.sidCompany}</td>
                                    <td>{dataLazada.accessToken}</td>
                                    <td>{dataLazada.appName}</td>
                                    <td>{dataLazada.sid}</td>
                                    <td>{dataLazada.sidCompany}</td>
                                    <td>{dataLazada.token}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th>Song</th>
                        <th>Artist</th>
                        <th>Year</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                        <td>Malcolm Lockyer</td>
                        <td>1961</td>
                    </tr>
                    <tr>
                        <td>Witchy Woman</td>
                        <td>The Eagles</td>
                        <td>1972</td>
                    </tr>
                    <tr>
                        <td>Shining Star</td>
                        <td>Earth, Wind, and Fire</td>
                        <td>1975</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default StoreRegisterView;
