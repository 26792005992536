import React, { useEffect, useState } from 'react';
import InputShopeeViewModel from './InputShopee.viewmodel';


import Footer from '../Footer/Footer';
import Header from '../Headerlogout/Headerlogout';
import BannerImage from '../../../assets/images/bg-login.png';
import Geer from '../../../assets/icons/Geer.png';
import Polygon from '../../../assets/icons/Polygon.png';

function InputLazada() {
    const { marginTop } = InputShopeeViewModel();

    

    return (
        <div>
            <Header />
            <div
                className="min-h-screen bg-center bg-cover bg-no-repeat flex items-center justify-center"
                style={{ backgroundImage: `url(${BannerImage})` }}
            >
                <div
                    style={{
                        backgroundSize: 'cover',
                        width: '80%',
                        height: '80%',
                        marginTop: marginTop // Adjust the value here to position it above the header
                    }}
                >
                    <br />
                    <br />
                    <br />
                    <a href="/Integrationpage">
                        <button className="shadow-lg bg-white hover:bg-gray-300 text-[#0068B5] font-semibold py-2 px-4 rounded-lg shadow flex items-center">
                            <img
                                src={Polygon}
                                alt="Integrates Icon"
                                className="mr-2"
                            />
                            <img
                                src={Geer}
                                alt="Integrates Icon"
                                className="mr-2"
                            />
                            BACK
                        </button>
                    </a>

                    <br />
                    <div className="relative mx-auto bg-white rounded-lg shadow-lg overflow-hidden justify-center items-center">
                        <div
                            className="w-4/5 px-6 py-8"
                            style={{ width: '100%' }}
                        >
                            <div className="text-center font-roboto font-bold text-xl leading-23 text-[#0068B5]">
                                Shopee API Integration
                            </div>
                            <br />

                            <div className="relative">
                                <p className="font-roboto font-bold text-lg leading-19">
                                    Shopee API Integration{' '}
                                    <a className="font-roboto font-thin text-14 leading-16 text-left align-middle">
                                        configure Shopee user id and api key to
                                        sync kumoten.com product
                                    </a>
                                </p>

                                <div className="flex justify-center items-center">
                                    <div
                                        className="font-roboto font-normal text-14 leading-16 mt-2 py-2 px-4"
                                        style={{
                                            width: '100vh'
                                        }}
                                    >
                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="block text-[#0068B5] font-bold mb-1"
                                            >
                                                Shopee Shop ID :
                                            </label>
                                            <input
                                                id="username"
                                                type="text"
                                                placeholder="Enter your Shopee Shop ID"
                                                className="block bg-[#78C5FE] border border-gray-300 rounded-lg shadow-sm py-2 px-4 placeholder-[#fff] focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="block text-[#0068B5] font-bold mb-1"
                                            >
                                                Shopee Partner ID :
                                            </label>
                                            <input
                                                id="username"
                                                type="text"
                                                placeholder="Enter your Shopee Partner ID"
                                                className="block bg-[#78C5FE] border border-gray-300 rounded-lg shadow-sm py-2 px-4 placeholder-[#fff] focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="block text-[#0068B5] font-bold mb-1"
                                            >
                                                Shopee Secret Key :
                                            </label>
                                            <input
                                                id="username"
                                                type="text"
                                                placeholder="Enter your Shopee Secret Key"
                                                className="block bg-[#78C5FE] border border-gray-300 rounded-lg shadow-sm py-2 px-4 placeholder-[#fff] focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
                                            />
                                        </div>
                                        <br />
                                        <div>
                                            <button
                                                type="submit"
                                                className="py-3 px-6 border border-transparent rounded-md shadow-sm text-white font-bold bg-[#0068B5] hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
                                            >
                                                SAVE SHOPEE KEY
                                            </button>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="block text-[#0068B5] font-bold mb-1"
                                            >
                                                Status :
                                            </label>
                                            {/* Green Yellow */}
                                            <a
                                                type="submit"
                                                className="text-center text-[#000] py-3 px-6 border border-transparent rounded-md shadow-sm font-bold bg-[#F3B75B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
                                            >
                                                Your Shopee partnerid, Shopid or
                                                seceretkey is not configure
                                            </a>
                                            {/* Green Status */}
                                            {/* <a
                                                type="submit"
                                                className="text-center text-[#000] py-3 px-6 border border-transparent rounded-md shadow-sm font-bold bg-[#52D414] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
                                            >
                                                Not Connected
                                            </a> */}
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>

                            {/* endtable */}
                        </div>
                    </div>
                    <br />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default InputLazada;
