import { useState, useEffect } from 'react';

import { getLazadaDataByParams } from '../../../data/storeRegister/repositories/storeRegisterLazada.reponsitories';
import {
    LazadaThirdPartnerRequestParams,
    LazadaThirdPartnerResponse
} from '../../../data/storeRegister/schemas/storeRegisterLazada.schemas';

function StoreRegisterViewModel() {
    const [lazadaData, setLazadaData] = useState<LazadaThirdPartnerResponse[]>(
        []
    );

    useEffect(() => {
        getAllData3RD({ sid: '555', companyCode: '1000' });
    }, []);

    async function getAllData3RD(params: LazadaThirdPartnerRequestParams) {
        const [error, data] = await getLazadaDataByParams(params);

        console.log(data);

        setLazadaData(data);
    }

    return { lazadaData };
}

export default StoreRegisterViewModel;
