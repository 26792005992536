import './index.css';

import React from 'react';
import { RouterProvider } from 'react-router';

import router from './routes/Routes';

function App() {
    return <RouterProvider router={router} />;
}

(App as React.FC).displayName = App.name;
export default App;
