import { useEffect, useState } from 'react';
import { retrieveLazadaDataByParams } from '../../../data/storeRegister/datasources/storeRegisterLazada.datasources';
import {
    LazadaThirdPartnerRequestParams,
    LazadaThirdPartnerResponse
} from '../../../data/storeRegister/schemas/storeRegisterLazada.schemas';

function InputLazadaViewModel() {

    
    const [marginTop, setMarginTop] = useState('-8vh');

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setMarginTop(isMobile ? '-5vh' : '8vh');
        };

        handleResize(); // Call the function initially

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [lazadaData, setLazadaData] = useState<LazadaThirdPartnerResponse[]>(
        []
    );

    const params: LazadaThirdPartnerRequestParams = {
        sid: '555',
        companyCode: '1000'
    };

    const callRetrieveLazadaDataByParams = async (
        params: LazadaThirdPartnerRequestParams
    ) => {
        const response = await retrieveLazadaDataByParams(params);

        if (!response.data.length) {
            return;
        }

        setLazadaData(response.data);
        console.log(response.data);
    };

    useEffect(() => {
        callRetrieveLazadaDataByParams(params);
    }, []);

    return { lazadaData,marginTop };

    
}

export default InputLazadaViewModel;
