import React from 'react';
import RedirectPageViewModel from './RedirectPage.viewmodel';

function RedirectPage() {
    const { sid, companyCode, hosturl, counter, platfrom } =
        RedirectPageViewModel();

    return (
        <div className="items-center max-w-sm p-6 mx-auto bg-slate-200 rounded-xl shadow-lg">
            <div className="shrink-0 space-y-3">
                <div className="space-y-3 text-center flex flex-col">
                    <span className="text-slate-800 text-4xl font-medium">
                        Redirect To {platfrom} Page
                    </span>
                    <span className="text-slate-800 text-2xl font-medium">
                        Redirecting in
                    </span>
                    {counter ? (
                        <span className="text-slate-800 text-center text-6xl font-semibold">
                            {counter}
                        </span>
                    ) : (
                        <span>...</span>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    <span>sid: {sid}</span>
                    <span>companyCode: {companyCode}</span>
                    <span>callback: {hosturl}</span>
                </div>
            </div>
        </div>
    );
}

export default RedirectPage;
