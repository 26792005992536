import React from 'react';

function Footer() {
    return (
        <footer className="bg-[#0068B5] py-4 h-32 flex items-center">
            <div className="max-w-screen-xl lg:pl-[80px] px-4 ">
                <p className="text-sm text-left text-white">
                    <a className="mr-4">Terms of Use</a>
                    <a className="mr-4">Privacy Policy</a>
                    <a className="mr-4">Return Policy</a>
                    <a>Contact</a>
                </p>

                <p className="text-sm text-left text-white">
                    Copyright © 2014 - 2023 OGN Online Sdn. Bhd. (1097062-A).
                    All Rights Reserved.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
