import { parseResponseError } from '../../../common/helpers/handle-error';
import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import {
    requestAddShopeeThirdPartner,
    retrieveAccessTokenShopee,
    retrieveShopeeShopInfo,
    retrieveUrlAutohrizeShopee
} from '../datasources/shopeeThirdPartyAuthorize.datasources';
import {
    ShopeeAccessTokenParams,
    ShopeeAccessTokenResponse,
    ShopeeApiRequestUrlAuthorize,
    ShopeePutRequest,
    ShopeeShopInfo,
    ShopeeShopInfoParams
} from '../schemas/shopeeThirdPartyAuthorize.schemas';

export async function getShopeeUrlAuthorize(
    params: ShopeeApiRequestUrlAuthorize
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await retrieveUrlAutohrizeShopee(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getShopeeAccessToken(
    params: ShopeeAccessTokenParams
): Promise<DatasourceReturnType<ResponseError, ShopeeAccessTokenResponse>> {
    try {
        const data = (await retrieveAccessTokenShopee(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getShopeeShopInfo(
    params: ShopeeShopInfoParams
): Promise<DatasourceReturnType<ResponseError, ShopeeShopInfo>> {
    try {
        const data = (await retrieveShopeeShopInfo(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);
        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addShopeeThirdPartner(
    body: ShopeePutRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await requestAddShopeeThirdPartner(body)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

// export async function getShopWarehouse(
//     params: ShopeeShopInfoParams
// ): Promise<DatasourceReturnType<ResponseError, ShopeeWareHouseResponse>> {
//     try {
//         const data = (await retrieveShopeeShopWareHouse(params)).data;

//         return [null!, data];
//     } catch (error) {
//         const reason = parseResponseError(error);

//         if (reason) {
//             return [reason, null!];
//         }

//         throw error;
//     }
// }
