import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import {
    retrieveAllLazadaData,
    retrieveLazadaDataByParams
} from '../datasources/storeRegisterLazada.datasources';
import {
    LazadaThirdPartnerRequestParams,
    LazadaThirdPartnerResponse
} from '../schemas/storeRegisterLazada.schemas';

export async function getAllLazadaData(): Promise<
    DatasourceReturnType<ResponseError, LazadaThirdPartnerResponse[]>
> {
    try {
        const data = (await retrieveAllLazadaData()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getLazadaDataByParams(
    params: LazadaThirdPartnerRequestParams
): Promise<DatasourceReturnType<ResponseError, LazadaThirdPartnerResponse[]>> {
    try {
        const data = (await retrieveLazadaDataByParams(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
