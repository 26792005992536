import React from 'react';
import ThirdPartyAuthorizeViewModel from './ThirdPartyAuthorize.viewmodel';

function ThirdPartyAuthorizeView() {
    const { counter, reqBody } = ThirdPartyAuthorizeViewModel();

    return (
        <div className="items-center max-w-sm p-6 mx-auto bg-slate-200 rounded-xl shadow-lg">
            <div className="shrink-0 space-y-3">
                <div className="space-y-3 text-center flex flex-col">
                    <span className="text-slate-800 text-4xl font-medium">
                        Redirecting To Master Web
                    </span>
                    <span className="text-slate-800 text-2xl font-medium">
                        Redirecting in
                    </span>
                    {counter ? (
                        <span className="text-slate-800 text-center text-6xl font-semibold">
                            {counter}
                        </span>
                    ) : (
                        <span>...</span>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    <span className="text-slate-800 text-lg font-semibold">
                        Store Name : {reqBody.storeName}
                    </span>
                    <span className="text-slate-800 text-lg font-semibold">
                        SID : {reqBody.sid}
                    </span>

                    <span className="text-slate-800 text-lg font-semibold">
                        Company Code: {reqBody.companyCode}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ThirdPartyAuthorizeView;
