import { useSearchParams } from 'react-router-dom';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';

import {
    addShopeeThirdPartner,
    getShopeeAccessToken,
    getShopeeShopInfo
} from '../../../data/thirdPartyAuthorize/repositories/shopeeThirdPartyAuthorize.repositories';
import {
    ShopeeAccessTokenParams,
    ShopeeAccessTokenResponse,
    ShopeePutRequest,
    ShopeeShopInfoParams
} from '../../../data/thirdPartyAuthorize/schemas/shopeeThirdPartyAuthorize.schemas';
import { DefaultValueParams } from '../../../data/thirdPartyAuthorize/schemas/thirdPartyAuthorize.schemas';
import { getDefaultValue } from '../../../data/thirdPartyAuthorize/repositories/thirdPartyAuthorize.repositories';

function ThirdPartyAuthorizeShopeeViewModel() {
    const [counter, setCounter] = useState<number>(10);
    const [reqBody, setReqBody] = useState<ShopeePutRequest>({
        sid: '',
        companyCode: '',
        appName: '',
        region: '',
        accessToken: '',
        created_by: '',
        created_on: '',
        updated_by: '',
        updated_on: '',
        sellerId: '',
        storeName: ''
    });

    const [defaultValue, setDefaultValue] = useState<Map<string, string>>(
        new Map<string, string>()
    );
    const [shopeeToken, setShopeeToken] = useState<
        ShopeeAccessTokenResponse | undefined
    >();
    const intervalRef = useRef<number | null>(null);

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const shopId = searchParams.get('shop_id');

    const sid = localStorage.getItem('sid');
    const companyCode = localStorage.getItem('companyCode');
    const callBack = localStorage.getItem('hosturl');

    const defaultValueKeyCode: DefaultValueParams[] = [
        { keyCode: 'SHOPEE_REQUEST_API_URL' },
        { keyCode: 'SHOPEE_PARTNER_ID' },
        { keyCode: 'SHOPEE_REDIRECT_TO_3RD' },
        { keyCode: 'SHOPEE_PARTNER_KEY' }
    ];

    useEffect(() => {
        getDefaultValueForRequest(defaultValueKeyCode);
    }, []);

    useEffect(() => {
        if (defaultValue && defaultValue.size > 0) {
            getAccessToken({
                code: code,
                host: defaultValue?.get('SHOPEE_REQUEST_API_URL')!,
                partnerId: +defaultValue?.get('SHOPEE_PARTNER_ID')!,
                tmpPartnerKey: defaultValue?.get('SHOPEE_PARTNER_KEY'),
                shopId: +shopId!
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        if (defaultValue && shopeeToken) {
            if (shopeeToken.refresh_token && shopeeToken.access_token) {
                getShopInfo({
                    accessToken: shopeeToken.access_token,
                    host: defaultValue?.get('SHOPEE_REQUEST_API_URL')!,
                    partnerId: +defaultValue?.get('SHOPEE_PARTNER_ID')!,
                    shopId: +shopId!,
                    partnerKey: defaultValue?.get('SHOPEE_PARTNER_KEY')!
                });
            } else {
                console.error('shopeeToken values are null or empty.');
            }
        }
    }, [shopeeToken]);

    const getDefaultValueForRequest = async (params: DefaultValueParams[]) => {
        const [, defaultValuesResponse] = await getDefaultValue(params);

        const defaultValues = defaultValuesResponse.reduce(
            (accumulate, defaultValue) => {
                accumulate.set(defaultValue.keyCode, defaultValue.xValue);
                return accumulate;
            },
            new Map<string, string>()
        );

        setDefaultValue(defaultValues);
    };

    const getAccessToken = useCallback(
        async (params: ShopeeAccessTokenParams) => {
            const [, token] = await getShopeeAccessToken(params);

            setShopeeToken(token);
        },
        []
    );

    const getShopInfo = useCallback(
        async (params: ShopeeShopInfoParams) => {
            const [, ShopInfo] = await getShopeeShopInfo(params);
            if (ShopInfo && shopeeToken) {
                setReqBody(prevState => ({
                    ...prevState,
                    sid: sid!,
                    companyCode: companyCode!,
                    accessToken: shopeeToken.access_token!,
                    appName: ShopInfo.shop_name!,
                    region: ShopInfo.region!,
                    sellerId: shopId!,
                    storeName: ShopInfo.shop_name!
                }));
            }
        },
        [shopeeToken]
    );
    const startCountDown = useCallback(() => {
        intervalRef.current = window.setInterval(() => {
            setCounter(prevCounter => {
                if (prevCounter === 0) {
                    clearInterval(intervalRef.current!);
                    redirectUrl();
                    return 0;
                }

                return prevCounter - 1;
            });
        }, 1000);
    }, [reqBody]);

    const putRequest = useMemo(async () => {
        if (reqBody.sellerId) {
            const [, data] = await addShopeeThirdPartner(reqBody);

            console.log(data);

            if (data) {
                startCountDown();
            }
        }
    }, [reqBody]);

    const redirectUrl = useCallback(() => {
        if (callBack) {
            const redirectUrl =
                //don't up
                'https://' +
                callBack +
                '/Register/ShopeeRegister.aspx' +
                `?code=${code}&refreshToken=${shopeeToken?.refresh_token}&shopId=${shopId}`;

            window.location.replace(redirectUrl);
            localStorage.clear();
        }
    }, [code, shopeeToken, callBack]);

    return { code, counter, reqBody };
}

export default ThirdPartyAuthorizeShopeeViewModel;
