import axios from 'axios';
import {
    AddLazadaThirdPartnerRequest,
    DefaultValueParams,
    DefaultValueResponse,
    LazadaAPIRequestParams,
    LazadaGetAccessToken
} from '../schemas/thirdPartyAuthorize.schemas';
import { ResponeLazadaSellerUser } from '../../../models/lazadaSellerUser';

export async function retrieveRegisterDataLazada() {
    return axios.get('/v1/lazada-third-partners');
}

export async function retrieveLazadaAPIUserData(
    params: LazadaAPIRequestParams
) {
    return axios.get<ResponeLazadaSellerUser>('/v1/connectLazadaApi', {
        params
    });
}

export async function retrieveDefaultValue(params: DefaultValueParams[]) {
    return axios.post<DefaultValueResponse[]>('/v1/default-value', params);
}

export async function requestAddLazadaThirdPartner(
    req: AddLazadaThirdPartnerRequest
) {
    return axios.put<string>('/v1/lazada-third-partner', req);
}

export async function retrieveAccToken(params: LazadaAPIRequestParams) {
    return axios.get<LazadaGetAccessToken>('/v1/requestAcctoken', {
        params
    });
}
