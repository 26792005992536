import axios from 'axios';

import {
    LazadaThirdPartnerResponse,
    LazadaThirdPartnerRequestParams
} from '../schemas/storeRegisterLazada.schemas';

export async function retrieveAllLazadaData() {
    return axios.get<Array<LazadaThirdPartnerResponse>>(
        '/v1/lazada-third-partners'
    );
}

export async function retrieveLazadaDataByParams(
    params: LazadaThirdPartnerRequestParams
) {
    // return axios.get('/v1/lazada-third-partner/' + params.companyCode);
    return axios.get('/v1/lazada-third-partner', {
        params
    });
}
