import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DefaultValueParams } from '../../../data/thirdPartyAuthorize/schemas/thirdPartyAuthorize.schemas';
import { getDefaultValue } from '../../../data/thirdPartyAuthorize/repositories/thirdPartyAuthorize.repositories';
import { ShopeeApiRequestUrlAuthorize } from '../../../data/thirdPartyAuthorize/schemas/shopeeThirdPartyAuthorize.schemas';
import { getShopeeUrlAuthorize } from '../../../data/thirdPartyAuthorize/repositories/shopeeThirdPartyAuthorize.repositories';

function RedirectPageViewModel() {
    const [counter, setCounter] = useState<number>(5);
    const [defaultValue, setDefaultValue] = useState<Map<string, string>>();
    const intervalRef = useRef<number | null>(null);
    const [searchParams] = useSearchParams();

    const sid = searchParams.get('sid');
    const companyCode = searchParams.get('companyCode');
    const hosturl = searchParams.get('hosturl');
    const platfrom = searchParams.get('platfrom');

    localStorage.setItem('sid', sid!);
    localStorage.setItem('companyCode', companyCode!);
    localStorage.setItem('hosturl', hosturl!);

    const url = defaultValue?.get('LAZADA_AUTHORIZE_LINK');

    const defaultValueKeyCode: DefaultValueParams[] = [
        { keyCode: 'LAZADA_OPEN_PLATFORM_APPKEY' },
        { keyCode: 'LAZADA_AUTHORIZE_CALLBACK_URL' },
        { keyCode: 'LAZADA_AUTHORIZE_LINK' },
        { keyCode: 'SHOPEE_REQUEST_API_URL' },
        { keyCode: 'SHOPEE_PARTNER_ID' },
        { keyCode: 'SHOPEE_REDIRECT_TO_3RD' },
        { keyCode: 'SHOPEE_PARTNER_KEY' }
    ];

    useEffect(() => {
        getDefaultValueForRequest(defaultValueKeyCode);
    }, []);

    useEffect(() => {
        if (defaultValue) {
            if (platfrom === 'Lazada') {
                const updateUrl = url
                    ?.replace(
                        '${callbackurl}',
                        defaultValue?.get('LAZADA_AUTHORIZE_CALLBACK_URL')!
                    )
                    .replace(
                        '${appkey}',
                        defaultValue?.get('LAZADA_OPEN_PLATFORM_APPKEY')!
                    );

                startCountDown(updateUrl);
            } else if (platfrom === 'Shopee') {
                const fetchUrl = async () => {
                    const updateUrl = await getUrlShopeeAuthorize({
                        host: defaultValue?.get('SHOPEE_REQUEST_API_URL'),
                        redirectUrl: defaultValue?.get(
                            'SHOPEE_REDIRECT_TO_3RD'
                        ),
                        partnerId: +defaultValue?.get('SHOPEE_PARTNER_ID')!,
                        partnerKey: defaultValue?.get('SHOPEE_PARTNER_KEY')
                    });

                    startCountDown(updateUrl);
                };
                fetchUrl();
            }
        }
    }, [defaultValue]);

    const getDefaultValueForRequest = async (params: DefaultValueParams[]) => {
        const [, defaultValuesResponse] = await getDefaultValue(params);

        //hash table + linked list
        const defaultValues = defaultValuesResponse.reduce(
            (accumulate, defaultValue) => {
                accumulate.set(defaultValue.keyCode, defaultValue.xValue);
                return accumulate;
            },
            new Map<string, string>()
        );

        setDefaultValue(defaultValues);
    };

    const getUrlShopeeAuthorize = async (
        params: ShopeeApiRequestUrlAuthorize
    ) => {
        const [, urlAuthorize] = await getShopeeUrlAuthorize(params);

        console.log(urlAuthorize);
        return urlAuthorize;
    };

    const startCountDown = useCallback((updateUrl: string | undefined) => {
        intervalRef.current = window.setInterval(
            (() => {
                let localCounter = counter;
                return () => {
                    if (localCounter === 0) {
                        clearInterval(intervalRef.current!);
                        setCounter(0);
                        window.location.replace(updateUrl!);

                        return;
                    }

                    localCounter--;
                    setCounter(timer => timer - 1);
                };
            })(),
            1000
        );
    }, []);

    return { sid, companyCode, hosturl, counter, platfrom };
}

export default RedirectPageViewModel;
