import React from 'react';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import ThirdPartyAuthorizeView from '../presentations/view/ThirdPartyAuthorize/ThirdPartyAuthorize.view';
import StoreRegisterView from '../presentations/view/StoreRegister/StoreRegister.view';
import Homepage from '../presentations/view/Homepage/Homepage.view';
import Login from '../presentations/view/Login/Login.view';
import Integrationpage from '../presentations/view/Integrationpage/Integrationpage.view';
import InputShopee from '../presentations/view/InputShopee/InputShopee.view';
import InputLazada from '../presentations/view/InputLazada/InputLazada.view';
import RedirectPage from '../presentations/view/Redirect/RedirectPage.view';
import ThirdPartyAuthorizeShopeeView from '../presentations/view/ThirdPartyAuthorizeShopee/ThirdPartyAuthorizeShopee.view';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const routerOptions = { basename: baseUrl! };

const router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Integrationpage" element={<Integrationpage />} />
            <Route path="/InputShopee" element={<InputShopee />} />
            <Route path="/InputLazada" element={<InputLazada />} />
            <Route
                path="/ThirdPartyAuthorize"
                element={<ThirdPartyAuthorizeView />}
            />
            <Route path="/Redirect" element={<RedirectPage />} />
            <Route path="/StoreRegister" element={<StoreRegisterView />} />
            <Route
                path="/ThirdPartyAutohrizeShopee"
                element={<ThirdPartyAuthorizeShopeeView />}
            />
        </React.Fragment>
    ),
    routerOptions
);

export default router;
